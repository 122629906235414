.line {
  display: table-row;
  flex-direction: column;
  align-items: center;
  /* display: ruby; */
  color: white;
}

.counterContainer {
  display: table;
  margin: 10px auto;
}

.timeDescription {
  font-size:3vw;
}

.time {
  font-size:15vw;
}

.days {
  font-size:15vw;
}

.ferias {
  font-size:15vw;
}

.middle-screen {
  margin-top: calc(45vh - 10vw)
}

@media only screen and (max-width: 1080px) {
  .timeDescription {
    font-size:5vw;
  }

  .time {
    font-size:18vw;
  }

  .days {
    font-size:18vw;
  }

  .ferias {
    font-size:18vw;
  }

  .middle-screen {
    margin-top: calc(45vh - 13vw)
  }
}

@media only screen and (max-width: 768px) {
  .timeDescription {
    font-size:6vw;
  }

  .time {
    font-size:22vw;
  }

  .days {
    font-size:22vw;
  }

  .ferias {
    font-size:22vw;
  }

  .middle-screen {
    margin-top: calc(45vh - 16vw)
  }
}

.timeContent {
  display: table-cell;
  padding-right: 1vw;
  /* position: absolute; */
}

