.titleContainer {
    padding: 5vh 0;
    z-index: 2;
    color: white;
    position: absolute;
    width: 100%;
    font-size: 3vw;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%); 
}

@media only screen and (max-width: 840px) {
    .titleContainer {
        font-size: 5vw;
    }
}

@media only screen and (max-width: 540px) {
    .titleContainer {
        font-size: 8vw;
    }
}