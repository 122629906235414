.background {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  overflow: auto;
  background-image: url("./background.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundFilter {
  background: rgba(0,0,0,0.4);
  min-height: 100vh;
  overflow: hidden;
}
